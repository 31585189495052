<template>
    <div>
      <b-modal
        id="bills-to-pay-documents-modal"
        hide-header
        hide-footer
        centered
        @hidden="onHide"
        size="lg"
      >
        <div class="modal-header">
          <div>
            <p class="modal-title">Arquivos anexados</p>
          </div>
          <div>
            <b-button
              size="lg"
              variant="outline"
              class="attach-button"
              @click="openNewDocumentModal"
            >
              <Clip class="clip" /> Anexar arquivo
            </b-button>
            <v-close class="icon" @click="closeModal" />
          </div>
        </div>
        <div class="modal-body list-area">
          <p class="info-message-box" v-if="files.length === 0">
            A conta ainda não possui documentos.
          </p>
          <ul class="list">
            <perfect-scrollbar>
              <li v-for="(file, index) of this.files" :key="index" @click="ev => openFileInNewTab(ev, file)">
                <div class="d-flex container image-item">
                  <div
                    class="container-data-files"
                  >
                    <div class="data-files">
                      <p class="name">{{ file.filename }}</p>
                    </div>
                    <p class="info">
                      {{
                        moment(file.created_at).format('DD [de] MMMM [de] YYYY')
                      }}
                    </p>
                  </div>
                  <div>
                    <Edit
                      class="attendance-file-option-edit"
                      @click="ev => showEditDocumentModal(ev, file)"
                    />
                    <Delete
                      class="attendance-file-option-delete"
                      @click="ev => onDeleteClick(ev, file)"
                    />
                  </div>
                </div>
              </li>
            </perfect-scrollbar>
          </ul>
        </div>
      </b-modal>
  
      <v-new-document-modal
        :fileType="'documento'"
        :bill="bill"
        @uploadNewDocument="uploadNewDocument"
        @updateNewDocument="updateNewDocument"
        :file="file"
      />
    </div>
  </template>
  
  <script>
  import moment from 'moment'
    
  export default {
    name: 'BillsToPayDocumentsModal',
    components: {
        'v-new-document-modal': () => import('@/components/General/NewDocumentModal'),
        'v-close': () => import('@/assets/icons/close.svg'),
        Clip: () => import('@/assets/icons/clip.svg'),
        Edit: () => import('@/assets/icons/edit.svg'),
        Delete: () => import('@/assets/icons/delete.svg'),
    },
    data() {
        return {
            filesCurrentTab: 'file',
            activeFile: null,
            files: [],
            file: {},
            toDelete: null,
        }
    },
    props: {
        bill: Object
    },
  
    methods: {
        moment,
        onShow() {
            if (this.bill && this.bill.bill_files.length) {
            this.files = this.bill.bill_files
            }
        },
        onHide() {
            this.files = []
            this.activeFile = null
            this.$emit('onHide')
        },
        closeModal() {
            this.$bvModal.hide('bills-to-pay-documents-modal')
        },
        openNewDocumentModal() {
            this.file = {}
            this.$bvModal.show('new-document-modal')
        },
        
        async setActiveFile(file) {
            const temporaryUrl = await this.getDocTemporaryUrl(file)
            this.activeFile = { ...file, temporaryUrl }
        },
    
        async openFileInNewTab(ev, file) {
            ev.stopPropagation();
            const temporaryUrl = await this.getDocTemporaryUrl(file)
            window.open(temporaryUrl, '_blank')
        },
    
        async getDocTemporaryUrl(billsToPayFile) {
            try {
            const response = await this.api.getDocTemporaryUrl(billsToPayFile.path)
            return response.data
            } catch (error) {
            throw new Error(400)
            }
        },
    
        onDeleteClick(ev, file) {
            ev.stopPropagation();
            if(!file.id) return;
            this.$swal({
            icon: 'question',
            html: `<div class="body-alert">Deseja realmente excluir o anexo <span>${file.filename}</span>? Essa ação não poderá ser desfeita.</div>`,
            showCancelButton: true,
            confirmButtonColor: '#F63220',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
            }).then(async res => {
            if (res.isConfirmed) {
                this.deleteFile(file)
            }
            })
        },
    
        async deleteFile(file) {
            const isLoading = this.$loading.show()
            try {
            await this.api.deleteBillFile(file.id)
            this.activeFile = null
            this.files = this.files.filter(fileArray => fileArray.id !== file.id)
            this.$toast.success('Arquivo deletado com sucesso')
            } catch (error) {
            this.$toast.error(error.message)
            } finally {
            isLoading.hide()
            }
        },
        uploadNewDocument(data) {
            this.files.push(data)
        },
        updateNewDocument(data) {
            this.files = this.files.filter(fileArray => fileArray.id !== data.id)
            this.uploadNewDocument(data)
        },
        showEditDocumentModal(ev, file) {
            ev.stopPropagation();
            this.file = file
            this.$bvModal.show('new-document-modal')
        },
        getAllDocs() {
            return this.files
        }
    },
    watch: {
      bill(newValue) {
        if (newValue) {
          this.onShow()
        }
      }
    }
  }
  </script>
  
  <style lang="scss">
  #bills-to-pay-documents-modal {
    .modal-content {
      border-radius: 8px;
  
      .modal-body {
        padding: 0 !important;
        margin: 0 !important;
  
        .modal-header {
          width: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          padding: 24px !important;
  
          .modal-title {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: var(--type-active);
          }
  
          .icon {
            width: 25px;
            fill: var(--neutral-500);
          }
        }
      }
    }
  
    .attach-button {
      color: var(--blue-500);
      margin-right: 20px;
      .clip {
        margin-top: -5px;
        stroke: var(--blue-500);
      }
    }
  
    .list-area {
      margin: -24px 0 24px 0;
  
      .info-message-box {
        background-color: #e6f9ff;
        border-radius: 8px;
        padding: 16px 26px;
        display: flex;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        align-items: center;
        margin-right: 24px;
  
        .icon {
          width: 24px;
          height: 24px;
          margin-right: 25px;
          stroke: #0088b3;
        }
      }
  
      .list {
        list-style-type: none;
        padding: 0;
        margin: 0;
  
        li {
          position: relative;
          padding: 25px 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
  
          &:before {
            width: calc(100% - 48px);
            height: 1px;
            background-color: var(--neutral-200);
            position: absolute;
            bottom: 0;
            left: 24px;
            content: ' ';
          }
  
          &:hover {
            background-color: var(--neutral-100);
            border-radius: 8px;
          }
  
          .container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
  
            .container-data-files {
              .data-files {
                display: flex;
                cursor: pointer;
                flex-direction: row;
                align-items: center;
              }
            }
          }
  
          .name {
            font-weight: 600;
            font-size: 16px;
            color: var(--type-active);
          }
  
          .info {
            font-weight: 600;
            font-size: 12px;
            color: var(--type-placeholder);
            white-space: nowrap;
  
            > span {
              width: 8px;
              height: 8px;
              margin-right: 8px;
              background-color: var(--states-success);
              border-radius: 4px;
              display: inline-block;
  
              &.orange {
                background-color: var(--light-orange-500);
              }
            }
          }
        }
      }
    }
    .attendance-file-option-edit {
      width: 22px;
      height: 22px;
      cursor: pointer;
      stroke: var(--blue-500);
    }
    .attendance-file-option-delete {
      width: 22px;
      height: 22px;
      cursor: pointer;
      margin-left: 5px;
    }
  }
  </style>
  